// import Vue from 'vue'
// import App from './App.vue'
// import { resizeScale } from '@/utils/resize'
//
// Vue.config.productionTip = false
//
//
// new Vue({
//   render: h => h(App),
// }).$mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'


const app = createApp(App)
app.config.productionTip = false
app.use(router)

app.mount('#app')

// resizeScale();
