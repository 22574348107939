<template>
    <!--<img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <RouterView />
<!--    <Home />-->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Home from './views/Home/index'
import {  RouterView } from 'vue-router'
import HelloWorld from './components/HelloWorld.vue'

export default {
    name: 'App',
    // components: {
    //     Home
    // },
    mounted() {
        // 根据不同路由跳转不同页面
        if (this._isMobile()) {
            // console.log('手机端')
            // this.$router.replace('/mb_index')
        } else {
            // console.log('pc端')
            // this.$router.replace('/pc_index')
        }
    },
    methods: {
        // 判断是否是手机端，如果是，返回true
        _isMobile () {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        }
    }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;
}
  body{
    margin: 0;
    padding: 0;
  }

/*** 边距 */


.m-0{margin: 0px!important;}
.m-1{margin: 5px!important;}
.m-2{margin: 10px!important;}
.m-3{margin: 15px!important;}
.m-4{margin: 20px!important;}
.m-5{margin: 25px!important;}
.m-6{margin: 30px!important;}

.ml-0{margin-left: 0px!important;}
.ml-1{margin-left: 5px!important;}
.ml-2{margin-left: 10px!important;}
.ml-3{margin-left: 15px!important;}
.ml-4{margin-left: 20px!important;}
.ml-5{margin-left: 25px!important;}
.ml-6{margin-left: 30px!important;}

.mr-0{margin-right: 0px!important;}
.mr-1{margin-right: 5px!important;}
.mr-2{margin-right: 10px!important;}
.mr-3{margin-right: 15px!important;}
.mr-4{margin-right: 20px!important;}
.mr-5{margin-right: 25px!important;}
.mr-6{margin-right: 30px!important;}

.mb-0{margin-bottom: 0px!important;}
.mb-1{margin-bottom: 5px!important;}
.mb-2{margin-bottom: 10px!important;}
.mb-3{margin-bottom: 15px!important;}
.mb-4{margin-bottom: 20px!important;}
.mb-5{margin-bottom: 25px!important;}
.mb-6{margin-bottom: 30px!important;}

.mt-0{margin-top: 0px!important;}
.mt-1{margin-top: 5px!important;}
.mt-2{margin-top: 10px!important;}
.mt-3{margin-top: 15px!important;}
.mt-4{margin-top: 20px!important;}
.mt-5{margin-top: 25px!important;}
.mt-6{margin-top: 30px!important;}

.p-0{padding: 0px!important;}
.p-1{padding: 5px!important;}
.p-2{padding: 10px!important;}
.p-3{padding: 15px!important;}
.p-4{padding: 20px!important;}
.p-5{padding: 25px!important;}
.p-6{padding: 30px!important;}

.pl-0{padding-left: 0!important;}
.pl-1{padding-left: 5px!important;}
.pl-2{padding-left: 10px!important;}
.pl-3{padding-left: 15px!important;}
.pl-4{padding-left: 20px!important;}
.pl-5{padding-left: 25px!important;}
.pl-5{padding-left: 30px!important;}

.pr-0{padding-right: 0!important;}
.pr-1{padding-right: 5px!important;}
.pr-2{padding-right: 10px!important;}
.pr-3{padding-right: 15px!important;}
.pr-4{padding-right: 20px!important;}
.pr-5{padding-right: 25px!important;}
.pr-6{padding-right: 30px!important;}

.pb-0{padding-bottom: 0!important;}
.pb-1{padding-bottom: 5px!important;}
.pb-2{padding-bottom: 10px!important;}
.pb-3{padding-bottom: 15px!important;}
.pb-4{padding-bottom: 20px!important;}
.pb-5{padding-bottom: 25px!important;}
.pb-6{padding-bottom: 30px!important;}

.pt-0{padding-top: 0!important;}
.pt-1{padding-top: 5px!important;}
.pt-2{padding-top: 10px!important;}
.pt-3{padding-top: 15px!important;}
.pt-4{padding-top: 20px!important;}
.pt-5{padding-top: 25px!important;}
.pt-6{padding-top: 30px!important;}


.ml-auto{margin-left: auto;}
/*** 边距 */

/**** 字号 ***/

.f10{font-size: 10px!important;}
.f11{font-size: 11px!important;}
.f12{font-size: 12px!important;}
.f13{font-size: 13px!important;}
.f14{font-size: 14px!important;}
.f15{font-size: 15px!important;}
.f16{font-size: 16px!important;}
.f17{font-size: 17px!important;}
.f18{font-size: 18px!important;}
.f19{font-size: 19px!important;}
.f20{font-size: 20px!important;}
.f21{font-size: 21px!important;}
.f22{font-size: 22px!important;}
.f23{font-size: 23px!important;}
.f24{font-size: 24px!important;}
.f25{font-size: 25px!important;}
.font-italic{font-style: italic;}
.no-bold{font-weight: normal!important;}
/**** 字号 end***/

.color-red{color: rgba(221, 34, 0, 1)}
.color-gray{color: rgb(122, 120, 120)
}


/********* flex 布局*****/

.d-flex{display: flex;}
.flex-1{ flex: 1;}
.flex-wrap{flex-wrap: wrap;}
.flex-direction-column{flex-direction: column;}
.flex-direction-row{flex-direction: row;}
.align-items-center{align-items: center;}
.align-items-baseline{align-items: baseline;}
.justify-content-center{justify-content: center;}
.justify-content-space-between{justify-content: space-between;}
.justify-content-space-around{justify-content: space-around;}
.justify-content-flex-end{justify-content: flex-end!important;}
.justify-content-end{justify-content: end!important;}
.justify-content-start{justify-content: start!important;}
.font-weight-bold{font-weight: bold;}
.text-align-center{text-align: center;}
.text-align-right{text-align: right;}
.text-decoration-line-through{text-decoration: line-through;}
</style>
