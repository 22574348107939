/**
 * apush 发送埋点指令
 * @type {{pv: apushSend.pv, click: apushSend.click}}
 */
export const apushSend = {
    /**
     * 事件埋点
     * @param code 事件id
     * @param msg 事件描述信息
     */
    click: function (code,msg){
        const {aplus_queue} = window;
        // 设置用户唯一性
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['uuid', getStoreImei()]
        });
        aplus_queue.push({
            action: 'aplus.record',
            arguments: [code, 'CLK', msg || {code}]
        });
    },
    /**
     * 主要是一些页面曝光的埋点统计 比如说统计页面的新增用户 或者页面的访问次数这些
     * @param page_name 页面的标识，自定义
     */
    pv: function (page_name) {
        const {aplus_queue} = window;
        // 设置用户唯一性
      /*  aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['timestamp', getStoreImei()]
        });*/
        // pv请求
        aplus_queue.push({
            action: 'aplus.sendPV',
            // 第二个参数userdata 为本次页面事件的扩展参数
            arguments: [{is_auto: false}, {page_name}]
        });
    }
};
