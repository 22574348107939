/*******apush start************/

/*页面标识*/
const PAGE_NAME = {
    // 增值服务首页
    HOME: 'home.html',
};


/**
 * apush 的常量
 */
export const STORE_APUSH = {
    PAGE_NAME,
}

/*******apush end************/
