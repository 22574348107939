
import bg1 from '@/assets/bg1.png'
import bg2 from '@/assets/bg2.png'
import bg3 from '@/assets/bg3.png'
import carousel1 from '@/assets/carousel-1.png'
import carousel2 from '@/assets/carousel-2.png'
import carousel3 from '@/assets/carousel-3.png'
import carousel4 from '@/assets/carousel-4.png'
import logo from '@/assets/logo.png'
import arrow from '@/assets/arrow.png'
import download from '@/assets/download.png'
import android from '@/assets/download-android.png'
import ios from '@/assets/downlod-ios.png'

import code_tutu from '@/assets/code-tutu.png'
import people from '@/assets/paople.png'
import computer from '@/assets/computer.png'
import text from '@/assets/text.png'
import bg_code from '@/assets/bg-code.png'
import earth from '@/assets/earth.png'
import trumpet from '@/assets/trumpet.png'
import kuang from '@/assets/downlod-kuang.png'

import guidanceArrow from '@/assets/guidanceArrow.png'



export const ImageRes = {
    bg1,bg2,bg3,
    carousel1,carousel2,carousel3,carousel4,
    logo,
    arrow,
    download,android,ios,
    code_tutu,
    people,computer,
    text,
    bg_code,
    trumpet,earth,
    kuang,guidanceArrow
}
