<template>
    <div class="home h100" id="mainContainer" v-if="!isMobile">
        <div class="header">
            <img style="width: 10%;height: 3%" :src="ImageRes.logo"/>
        </div>
        <div class="swiper-1">
            <div class="w100 view d-flex">
                    <img :src="ImageRes.computer" class="computer"/>
                    <div style="width: 20%">
                        <swiper :options="swiperOption">
                            <swiper-slide class="text-align-center">
                                <img class="swiper-slide-item" :src="ImageRes.carousel1" />
                            </swiper-slide>
                            <swiper-slide class="text-align-center">
                                <img class="swiper-slide-item" :src="ImageRes.carousel2" />
                            </swiper-slide>
                            <swiper-slide class="text-align-center">
                                <img class="swiper-slide-item" :src="ImageRes.carousel3" />
                            </swiper-slide>
                        </swiper>
                    </div>

                <div class="d-flex flex-direction-column ">
                    <img class="text" :src="ImageRes.text" />
                    <img class="code_tutu" :src="ImageRes.code_tutu" alt=""  style="margin: 10% 0% 0% 20%">
                </div>
                <div class="d-flex flex-direction-column align-items-center">
                    <img class="trumpet" :src="ImageRes.trumpet" alt="">
                    <img class="earth" :src="ImageRes.earth" alt="">
                </div>
            </div>
        </div>
        <div class="footer">
           <span>广东小狼星物联有限公司版权所有</span>
          <div class="d-flex">
            <img class="ml-1" src="../../assets/gov.png" />
            <a href="http://www.beian.gov.cn/portal/index.do" target="_blank">粤公网安备 44190002007422号  </a>
          </div>

            <a href="https://beian.miit.gov.cn" target="_blank" class="ml-1">粤ICP备 2022095531号©2023</a>
        </div>
    </div>
    <div class="home h100" id="mainContainer" v-else>
      <div class="header">
        <img style="width: 35%;height: 3%" :src="ImageRes.logo"/>
        <img class="trumpet" style="width: 20%;top: -25%;right: 5%;" :src="ImageRes.trumpet" alt="">
      </div>
<!--      <div class="swiper-1">-->
        <div class="w100 view d-flex flex-direction-column align-items-center">
<!--          <img :src="ImageRes.computer" class="computer"/>-->
          <div style="width: 35%">
            <swiper :options="swiperOption">
              <swiper-slide class="text-align-center">
                <img class="swiper-slide-item" :src="ImageRes.carousel1" />
              </swiper-slide>
              <swiper-slide class="text-align-center">
                <img class="swiper-slide-item" :src="ImageRes.carousel2" />
              </swiper-slide>
              <swiper-slide class="text-align-center">
                <img class="swiper-slide-item" :src="ImageRes.carousel3" />
              </swiper-slide>
            </swiper>
          </div>

          <div class="d-flex flex-direction-column align-items-center">
            <img class="text" :src="ImageRes.text" />
            <img class="code_tutu" :src="ImageRes.code_tutu" alt=""  style="margin: 10% 0% 0% 0%">
          </div>

        </div>
<!--      </div>-->
      <div class="footer flex-direction-column" style="position: absolute">
        <span class="f13">广东小狼星物联有限公司版权所有</span>
        <div class="d-flex">
          <img class="ml-1" src="../../assets/gov.png" />
          <a href="http://www.beian.gov.cn/portal/index.do" target="_blank" class="f13">粤公网安备 44190002007422号  </a>
        </div>

        <a href="https://beian.miit.gov.cn" target="_blank" class="ml-1 f13">粤ICP备 2022095531号©2023</a>
      </div>
    </div>
</template>

<script>
    import {ImageRes} from "@/image";

    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/swiper.less';
    import {apushSend} from "@/utils";
    import {STORE_APUSH} from "@/utils/constant";
    export default {
        name: 'home',
        components:{
            Swiper,SwiperSlide
        },
        data(){
            return {
                ImageRes,
                isMobile: false,
                swiperOption: { //同swiper
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
                    autoplay: {
                        delay: 5000, //自动切换的时间间隔，单位ms
                        stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
                        stopOnLastSlide: true, //如果设置为true，当切换到最后一个slide时停止自动切换。
                        disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。
                        reverseDirection: false, //开启反向自动轮播。
                        waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        },
        mounted() {
          let ua = navigator.userAgent.toLowerCase();//获取判断用的对象

          const isMobile = /iPhone|iPad|iPod|Android|iOS/i.test(navigator.userAgent);

          this.isMobile = isMobile;

            apushSend.pv(STORE_APUSH.PAGE_NAME.HOME);
        }
    }
</script>

<style lang="less" scoped>
    .home{
        position: relative;
        height: 100vh;
        overflow: hidden;
        background: url("~@/assets/bg2.png") no-repeat center;
        background-size: 100% 100%;
    }
    .header{
        position: absolute;
        z-index: 100;
        top: 5%;
        left: 5%;
        /*transform: translate(-50%);*/
        width: 90%;
        margin: 0 auto;
        padding: 0 2%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
    .arrow{
        margin-left: 20px;
        /*width: 106px;*/
        /*height: 17px;*/
    }
    .swiper{
        height: 100%;
    }

    .swiper-1{
        position: relative;
        width: 100%;
        height: 100%
    }
    .text{
        width: 100%;
        /*height: 30%;*/
    }
    .code-box{
        background: url("~@/assets/bg-code.png") 100% 100% no-repeat;
        /*width: 100%;*/
        /*height: 50%;*/
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .code-box>img{
        width: 50%;
        height: 50%;
        position: relative;
        top: 22px;
        border-radius: 30px;
    }

    .view{
        position: absolute;
        width: 100%;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .swiper-slide-item{
        width:100%;
        height: 50%;
    }

    .computer{
        width: 10%;
        height: 10%;
        position: relative;
        left: 5%;
        z-index: 99;
        transition: transform 0.3s;
        animation: scale 1s;
    }
    .computer:hover {
        transform: scale(1.2);
    }

    .earth{
        width: 18%;
        height: 50%;
        position: absolute;
        bottom: -10%;
        right: 5%;
        transition: transform 0.3s;
        animation: scale 1s;
    }

    .earth:hover,.trumpet:hover {
        transform: scale(1.2);
    }
    .code-tutu{
        width: 10%;
        height: 30%;
        position: absolute;
        left: 50%;
        top: 30%;
    }
    .download{
        position: relative;left: 10%;top: 10%;
    }

    .download *:hover{
        .code{
            opacity: 1!important;
        }
        /*transform: translate(-50%);*/
    }
    .android,.ios{
        width: 28%;
        position: relative;
    }
    .android>img,.ios>img{
        position: relative;
        width: 100%;
        cursor: pointer;
    }
    .code{
        position: relative;
        opacity: 0;
        left: 50%;
        transform: translate(-50%,20px);
        top: -20px;
        width: 80%;
        height: 80%;
        /*background-image: url("~@/assets/downlod-kuang.png");*/
        background-size: cover;
        transition: opacity .3s,transform .3s;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .code_tutu{
        width: 30%;
    }
    .code-title{
        font-size: 18px;
        font-weight: bold;
    }
    .footer{
        position: fixed;
        bottom: 10px;
        left: 0;
        text-align: center;
        width: 100%;
        font-size: 14px;
        color: #595959;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .footer >a {
        color: #595959;
        text-decoration: none;
    }
    .code>img{
        width: 100%;
        height: 80%;
    }

    .trumpet{
        width: 10%;
        position: absolute;
        top: -20%;
        right: 10%;
        transform: rotate(-50deg);
        animation: trumpetScale .8s linear infinite;
    }

    @keyframes scale{
        0%{transform:scale(2);}
        50%{transform:scale(1);}
        100%{transform:scale(1);}
    }
    @keyframes transform{
        100%{transform:scale(1.2);}
    }


    @keyframes trumpetScale {
        0%{transform:scale(1);}
        50%{transform:scale(1.1);}
        100%{transform:scale(1);}
    }



</style>
