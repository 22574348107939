import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/index'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/link',
        name: 'link',
        component: () => import('../views/Home/link')
    }
]

const router = createRouter({
    history: createWebHistory('/'),
    routes
})

export default router